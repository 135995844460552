import React, { useState, useRef, useEffect } from 'react'
import { capitalizeString } from '../../services/helpers';
import Background from '../../assets/images/mindfulness/default_ambience.jpeg'
import  "../../assets/stylesheets/shared/ambience_player.scss";

const AmbiencePlayer = ({isPlaying, setShowTools, ambienceAudios, ambienceDisplayRef}) => {
  // state
  const [value, setValue] = useState(Math.floor(ambienceAudios.length/2))
  const [src, setSrc] = useState(ambienceAudios[Math.floor(ambienceAudios.length/2)].url)
  // references
  const ambiencePlayer = useRef();   // reference our audio component
  const volumeBar = useRef();   // reference our volume bar


  useEffect(()=>{
    if(isPlaying){
        ambiencePlayer.current.play();
    }
    else{
        ambiencePlayer.current.pause();
    }
  },[isPlaying,src])

  useEffect(()=>{
    let ambience_volume = localStorage.getItem('amb_volume')
    if (ambience_volume != undefined){
      ambiencePlayer.current.volume = Number(ambience_volume)
      volumeBar.current.value = Number(ambience_volume)
    }
    else{
      ambiencePlayer.current.volume = 0;
      volumeBar.current.value = 0;
    }
  },[])
  
  const changeVolume = (e) => {
    ambiencePlayer.current.volume = volumeBar.current.value;
    localStorage.setItem('amb_volume',volumeBar.current.value)
    let target = e.target
    if (e.target.type !== 'range') {
      target = document.getElementById('range')
    } 
    const min = target.min
    const max = target.max
    const val = target.value 
    target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
  
  }
  
  function closeAmbience(){
    // ambienceDisplayRef.current.style.display = 'none'
    setShowTools(null);
  }

 
  return (
    <div className='ambience_player_wrapper'>
      <div className='ambience_player_container'>
        <audio loop ref={ambiencePlayer} src={src} preload="metadata"></audio>
        <div className='volumebar_container'>
            <input type="range" className='volumeBar' max="1" min="0"  step="0.01" ref={volumeBar} onChange={changeVolume} />
            <div className='volume_icn_container'></div>
        </div>
        <div className='ambience_img_wrapper'>
          <ul className='ambience_ul'>
            {
              ambienceAudios && ambienceAudios.map((ambience, index)=>{
              let protocol = /(^https?)(:)\/\//;
              let imgURL = ambience.thumbnail;
              let thumbnailElement;
              if ((imgURL != null) && (imgURL != '') && (protocol.test(imgURL))) {
                thumbnailElement = <img src={imgURL}  alt=''/>;
              }else {
                thumbnailElement = <img src={Background}  alt=''/>;
              }
              return (
                <li key={index} className={`ambience_li ${value==index ? 'active': ''}`}>
                  <div className={`ambience_img_container ${value==index ? 'active': ''}`}>
                    <div className={`ambience_img ${value==index ? 'active': ''}`}  onClick={()=> {setSrc(ambience.url); setValue(index)}}>
                      {
                          thumbnailElement
                      }
                    </div>
                  </div>
                  { value == index && <span className='ambience_title'>{capitalizeString(ambience.name)}</span>}
                </li>
              )
              })
            } 
          </ul>
        </div>
       </div>
       <div className='ambience_close_container'>
        <div className='ambience_close' onClick={closeAmbience}></div>
       </div>
    </div>
  )
}

export { AmbiencePlayer }