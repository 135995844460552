import React, { useEffect, useState } from 'react';
import Background from '../../assets/images/default_background.png'
import BackgroundImage from '../shared/BackgroundImage';
import '../../assets/stylesheets/Mindfulness/mindfulness_session.scss'
export default  function MindfulnessSession({msession, series_or_collection, from}){
  
  let author = msession.author ? msession.author : msession.narrator;
  let total_count;
  let thumbnail = msession.session_thumbnail ? msession.session_thumbnail : msession.group_thumbnail ;
    if (series_or_collection){
      if(msession.group_type){
        total_count = msession.sessions_count || msession.mindfulness_sessions_list.length;
      }
    }

    let backgroundElement;
    let protocol = /(^https?)(:)\/\//;
    if ((thumbnail != null) && (thumbnail != '') && (protocol.test(thumbnail))) {
        backgroundElement = {backgroundImage: 'url(' + thumbnail + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'}
    }else {
        backgroundElement =     backgroundElement = {backgroundImage: 'url(' + Background + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'};
    }

    return(
        <>
        <div className={`session_wrapper with_desc ${from}`}>
            <div className={`session_container with_desc ${msession.group_type?.toLowerCase()}`} style={backgroundElement}>
                {/* {<BackgroundImage imgURL={thumbnail}/>} */}
                 <div className='sc_m_session_content'>
                    {series_or_collection && <div className='sc_m_session_left'>
                        <div className='sc_m_session_count_container'>
                            <div className='sc_m_session_count'> {total_count}</div>
                        </div>
                    </div>}
                </div>
                {msession.status == 'draft' &&   <div className={`collection_audio_processing_container ${msession.status}`}><span className='draft_processing_icn'></span><span className='audio_processing_txt draft'>Draft</span></div>}
                {msession.status == 'processing' && <div className='collection_audio_processing_container'><span className='audio_processing_icn'></span><span className='audio_processing_txt'>Audio Processing</span></div>}
            </div>
        </div>
        <div className='session_detail_container'>
                <div className='session_detail title'> {msession.title} </div>
                <div className='session_detail description'>{msession.description}</div>  
        </div>
        </>
    );
}