import {  Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import {  getUserData, saveUserData } from './helpers';
import { checksession } from './Events';
import { useEffect, useState } from 'react';
import { AudioPlayerProvider, useAudioPlayer } from './AudioPlayerContext';
import { useSession } from './SessionProvider';
import { getSessionStatus, validateSession } from './Validation';
import NavMenu from '../Components/shared/NavMenu';
import '../App.scss';
import { update_assignemnts } from './colegia_constants';
import Feedback from '../Components/shared/Feedback';
import AudioPlayer from '../Components/shared/AudioPlayer';
// import { requestPermission } from '../firebase-config';
export default function AppRoutes() {
 
  const location = useLocation();

  const { isLoggedIn, user_info } = useSession();
  const userInfo = getUserData().user_info
  const [sessStatusFlag, setSessStatusFlag] = useState(false)
  const [isDemo, setIsDemo] = useState(false)// useState(Object.keys(userInfo || {}).length > 0 ? (userInfo?.user_account_uuid === '0853f8df-2f93-4762-b907-0da9001d56b7' || userInfo?.user_account_uuid === '2cec55bb-1a87-4405-9159-25f1e6073020') : false);
  const navigate = useNavigate()
  const isPathAllowed = (pathname) => pathname.includes('teacher') || pathname.includes('alaiaweb') || pathname.includes('audio');
  const { isAudioPlayerVisible } = useAudioPlayer();

  // const handleRequestPermission = () => {
  //   requestPermission().then(() => {
  //     console.log('Notification permission granted.');
  //   }).catch((error) => {
  //     console.error('Error getting permission or token:', error);
  //   });
  // };

  async function handlelogin() {
    const payload = {
      Request: {
        Client: {
          platform: 'web',
        },
      },
    };
  
    console.log('payload', payload);
  
    try {
      const res = await checksession(payload);
  
      if (res) {
        if (res.data?.session?.status) {
          const data = res.data.session;
          const user_info = data.user_data;

          const element = document.getElementById('alaia_main_body_id');
          if (element && element.style) {
            element.style.backgroundImage = 'none';
          }
 
          if (user_info.session_type === 'admin_session' && !( location.pathname.includes('alaiaweb') || location.pathname.includes('audio') || location.pathname.includes('view'))) {
            window.open(`https://${window.location.hostname}/app/admin?lang=en`, '_self');
            return; 
          }
  
          const userData = {
            isLoggedIn: data.status,
            user_info,
          };
          saveUserData(userData);
          setSessStatusFlag(true);
  
          const isDemo = ['0853f8df-2f93-4762-b907-0da9001d56b7', '2cec55bb-1a87-4405-9159-25f1e6073020'].includes(user_info.user_account_uuid);
          setIsDemo(false);
  
          handlePostLoginRedirection();
        } else {
          navigate('/login');
        }
      }
    } catch (error) {
      console.error('Error in handlelogin:', error);
    }
  }

  function handlePostLoginRedirection() {
    let redirect_url = localStorage.getItem('alaia_redirect_url');
    
    if (window.location.href.indexOf("admin") > -1 || userInfo?.session_type === 'admin_session') {
      redirect_url = null;
    }
  
    if (redirect_url) {
      localStorage.removeItem('alaia_redirect_url');
      localStorage.setItem('usage_tracking', JSON.stringify({
        url: redirect_url,
        usage_type: find_type(redirect_url),
        start_time: Date.now(),
      }));
  
      const match = redirect_url.match(/\/\/[^\/]+\/(.*)$/);
      if (match) {
        navigate(match[1]);
      }
    }
  }
  

  function find_type(url){
    if(url){
        if(url.indexOf('view') > -1) 
            return '';
        if(url.indexOf('reflect') > -1) 
            return 'reflect';
        else if(url.indexOf('activity') > -1)
            return 'activity';
        else if(url.indexOf('learn') > -1){
            if(url.indexOf('admin') > -1) return 'admin-learn';
            else return 'learn';
        }
        else return 'mindfulness'
    }
    else return ''
  }

  useEffect(()=>{
    const body = document.getElementById("alaia_main_body_id")
    console.log(window.location.href.indexOf("learn") > -1);
    if (window.location.href.indexOf("learn") > -1) {
       body.style.backgroundColor = "#FFF"
      console.log("fff")
    }
    else {
       body.style.backgroundColor = "#F7F7F7"
       console.log("fff7")
    }
    },[window.location.href])

  useEffect(() => {
    const handleEffect = async () => {
      const url_string = new URL(window.location.href);
      const login = url_string.searchParams.get('login');
  
      if (login) {
        window.history.pushState(null, null, window.location.pathname);
      }
  
      if (!getSessionStatus() || login === 'true') {
        await handlelogin();
      } else {
        if (validateSession()) {
          setSessStatusFlag(true);
  
          const element = document.getElementById('alaia_main_body_id');
          if (element && element.style) {
            element.style.backgroundImage = 'none';
          }
  
          if (userInfo?.session_type === 'admin_session'  && !( location.pathname.includes('alaiaweb') || location.pathname.includes('audio') || location.pathname.includes('view'))) {
            window.open(`https://${window.location.hostname}/app/admin?lang=en`, '_self');
            return; 
          }
           
          const isDemo = ['0853f8df-2f93-4762-b907-0da9001d56b7', '2cec55bb-1a87-4405-9159-25f1e6073020'].includes(userInfo?.user_account_uuid);
          setIsDemo(false);
  
          if (isDemo) {
            localStorage.removeItem('alaia_redirect_url');
            localStorage.setItem('usage_tracking', JSON.stringify({
              url: `https://${window.location.hostname}/learn`,
              usage_type: 'learn',
              start_time: Date.now(),
            }));
            return;
          }
          handlePostLoginRedirection();
        } else {
          await handlelogin(); 
        }
      }
    };
  
    handleEffect();
  }, []); 
  

  useEffect(() => {

    const extractAudioId = (url) => {
      const audioMatch = url.match(/\/audio\/([^\/?]+)/);
      return audioMatch ? audioMatch[1] : null;
    };
    
    const createPayload = (assignmentUuid, audioId, keyPath, colegiaAssignmentId, lesson_uuid, teacher_email) => {
      let assignment = {};
      if (assignmentUuid) {
        assignment.uuid = assignmentUuid;
      } else {
        assignment.colegia_assignment_id = colegiaAssignmentId;
        assignment.lesson_uuid = lesson_uuid;
        assignment.teacher_email = teacher_email;
      }
      assignment.status = 'open';
      const payload = { mwsRequest:{ String: 
        {Request:
        { Session: { token: '' ,loggedin_user_type: userInfo.user_type},
        Client: {platform: 'web',identity: '', version:"1.0", session_hash:""},
        Api: {version: '1.0'},
        ManagedUser: {uuid:   userInfo.user_account_uuid, email: userInfo.email},
        Assignment: assignment
    }}}}
    
      if(audioId){
        payload.mwsRequest.String.Request.Assignment = {
          ... payload.mwsRequest.String.Request.Assignment,
          assignment_type: 'mindfulness',
          attachment_uuid: audioId
        }
      }
      else if(keyPath){
        
        payload.mwsRequest.String.Request.Assignment = {
          ... payload.mwsRequest.String.Request.Assignment,
          assignment_type: 'file',
          attachment_uuid: keyPath
        }
        
      }
      else{
        payload.mwsRequest.String.Request.Assignment = {
          ... payload.mwsRequest.String.Request.Assignment,
          assignment_type: 'others',
          attachment_uuid: url
        }
      }
      return payload;
    };

    const url = window.location.href;
    const params = new URLSearchParams(window.location.search);
    const alaia_assignment_uuid = params.get('alaia_assignment_uuid');
    const colegia_assignment_id = params.get('colegia_assignment_id');
    const lesson_uuid = params.get('lesson_uuid');
    const teacher_email = params.get('email');
  
    if (userInfo && ( alaia_assignment_uuid || colegia_assignment_id) && userInfo?.session_type !== 'admin_session') {
      const audioId = extractAudioId(url);
      const keyPath = params.get('key_path') || null;

      const payload = createPayload(alaia_assignment_uuid, audioId, keyPath, colegia_assignment_id, lesson_uuid, teacher_email);
      update_assignemnts(payload); 
    }   
  }, [userInfo]);
console.log(isAudioPlayerVisible)
  return <>
    
     {sessStatusFlag && (userInfo?.session_type!=='admin_session' || (location.pathname.includes('alaiaweb') || location.pathname.includes('audio') || location.pathname.includes('view')))&& <div className={`page_container ${userInfo?.session_type!='admin_session' &&  !(((window.location.href.indexOf("preview") > -1) && (window.location.href.indexOf("learn"))) || (window.location.href.indexOf("view") > -1) ) ? 'main' : ''}`} id='app_main_page_container'>
        { userInfo?.session_type!='admin_session' &&  !(((window.location.href.indexOf("preview") > -1) && (window.location.href.indexOf("learn"))) || (window.location.href.indexOf("view") > -1) ) && <NavMenu/>}
          <div className='main_wrapper'>
            <div className='main_container'>
              {isDemo ? isPathAllowed(location.pathname) ? <Outlet/> : <Navigate to={'/teacher/learn'}/> : <Outlet/>}
              <Feedback/>
              {(isAudioPlayerVisible || (!isAudioPlayerVisible && (window.location.href.indexOf("audio") > -1))) && <AudioPlayer />}
            </div>
        </div>
      </div>}
  </>
  
  

}