import '../../assets/stylesheets/shared/background_image.scss'
import Background from '../../assets/images/default_background.png'

function BackgroundImage({ imgURL, dimension, ...rest }) {
  let backgroundElement;
  let protocol = /(^https?)(:)\/\//;
  
  if ((imgURL != null) && (imgURL != '') && (protocol.test(imgURL))) {
    backgroundElement = {backgroundImage: 'url(' + imgURL + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'}
  }else {
    backgroundElement =     backgroundElement = {backgroundImage: 'url(' + Background + ')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center center'};
  }
  return (
    <div className={`background_image_wrapper`} style={backgroundElement}>
    
    </div>
  )
}

export default BackgroundImage;
